
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$maincolor: #0071BA;
$azulfont: #006D9C;
$lightBlue: #0088E0;
$backGray: #F9F9F9;

$xlwidth: 1530px;
$lgwidth: 1200px;
$mdwidth: 1024px;
$smwidth: 768px;
$xswidth: 324px;

$xsBreak: 576px;
$smBreak: 768px;
$mdBreak: 992px;
$lgBreak: 1200px;

$xlHeaderHeight: 130px;
$lgHeaderHeight: 120px;
$mdHeaderHeight: 110px;  
$smHeaderHeight: 70px; 

:root {
    --xlHeaderHHeight: 130px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

h1, h2, h3 {
    font-family: 'poppins', sans-serif;
    font-weight: 700;
}

a {
    text-decoration: none;
}
