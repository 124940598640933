
@import './globals';
@import 'include-media';

.Productos {
    position: relative;
    width: 100%;

    .Lineas {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: stretch;
        @include media("<=desktop") {

        }
        a {
            position: relative;
            width: 0;
            min-width: 315px;
            flex-grow: 1;
            overflow: hidden;
            & > div {
                img {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: all 0.4s;
                    transform: scale(1.01);
                }
                h2 {
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                    font-size: 48px;
                    line-height: 1.1;
                    text-transform: uppercase;
                    width: 80%;
                    padding: 15px 0;
                    border: 1px solid white;
                    background-color: #0071BA7c;
                    transition: all 0.4s;
                    cursor: pointer;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include media("<=phone") {
                        font-size: 18px;
                    }
                    @include media(">phone", "<=tablet") {
                        font-size: 24px;
                    }
                    @include media(">tablet", "<=desktop") {
                        font-size: 36px;
                    }
                    &:hover {
                        width: 100%;
                        padding: 400px 00px;
                        color: orangered;
                        background-color: #0071BA0B;
                    }
                }
            }
        }
    }
}
