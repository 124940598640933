
@import './globals';
@import 'include-media';

.HeaderBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $xlHeaderHeight;
    background-color: $maincolor;
    mix-blend-mode: multiply;
    z-index: 40;
    @include media("<=phone") {
        height: $smHeaderHeight;
    }
    @include media(">phone", "<=tablet") {
        height: $mdHeaderHeight;
    }
}


