
@import './globals';
@import 'include-media';

.GeneralCard {
    $h2Height: 100px;
    $imgBox: 330px;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    transition: all 0.4s;
    background-color: white;

    & > div > div {
        width: 100%;
        height: $imgBox;
        border-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        transition: all 0.4s;
        img {
            max-width: 100%;
            max-height: 100%;
            object-position: center;
            object-fit: contain;
            transition: all 0.4s;
        }
        &::after {
            position: absolute;
            content: "";
            top: 0;
            width: 100%;
            height: $imgBox;
            background-color: rgba(0, 0, 0, 0.05);
            transition: all 0.4s;
        }
        &:hover::after {
            height: 0;
            background-color: rgba(255, 255, 255, 0.0);
        }
    }

    h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 0.5rem;
        text-transform: uppercase;
        font-size: 18px;
        text-align: center;
        color: black;
        line-height: 1.2;
        transition: all 0.4s;
        box-shadow: 0 -8px 0 0 $lightBlue inset,;
    }
    &:hover {
        cursor: pointer;
        h2 {
            color: white;
            box-shadow: 0 -100px 0 0 $lightBlue inset;
        }
        img {
            transform: translate(0, -10px)
        }
    }
}


