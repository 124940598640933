
@import 'globals';
@import 'include-media';

.Lindustrial {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .linea-industrial {
        position: relative;
        display: flex;
        width: 100%;
        background-image: url(../img-general/background-linea-industrial.webp);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        @include media("<=phone") {
            background-position: -100px 0;
        }
        &::after {
            content: "";
            padding-top: 25%;
        }
        @include media("<=phone") {
            &::after {
                content: "";
                padding-top: 60%;
            }
        }
        div {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .rotulo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 0px 25px 0;
            img {
                width: 40%;
                padding-top: $xlHeaderHeight;
                @include media("<=phone") {
                    display: none;
                }
            }
            @include media("<=phone") {
                justify-content: flex-end;
                align-items: flex-end;
                padding: 0 25px 25px 0;
            }
            h1 {
                align-items: center;
                font-size: 72px;
                line-height: 1;
                text-align: center;
                color: #29A9E0;
                @include media("<=phone") {
                    font-size: 30px;
                    text-align: end;
                }
                @include media(">phone", "<=tablet") {
                    font-size: 36px;
                }
                @include media(">tablet", "<=desktop") {
                    font-size: 48px;
                }
                @include media(">desktop", "<=largedesktop") {
                    font-size: 60px;
                }
            }
        }
    }

    .tratamientos {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        flex-wrap: wrap;
        width: 100%;
        .aire {
            background-image: url(../img-general/tratamiento-de-aire.webp);
            background-repeat: no-repeat;
            background-position: center top;
            background-size: cover;
        }
        .agua {
            background-image: url(../img-general/tratamiento-de-agua.webp);           
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 250px;
            flex-basis: 0;
            flex-grow: 1;
            &::before {
                content: "";
                padding-top: 70%;
            }
            @include media("<=phone") {
                &::before {
                    content: "";
                    padding-top: 80%;
                }
            }
        }
        a {
            width: 100%;
            h2 {
                margin: 0 auto;
                font-size: 24px;
                color: white;
                text-align: center;
                line-height: 1.1;
                padding: 10px 0;
                border: 1px solid  white;
                width: 50%;
                border-radius: 100px;
                text-transform: uppercase;
                background-color: rgba(0, 0, 0, 0.4);
                transition: all 0.2s;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.4);
                    transform: scale(1.05);
                    cursor: pointer;
                }
                @include media("<=phone") {
                    font-size: 18px;
                    width: 90%;
                }
                @include media("<=desktop") {
                    font-size: 18px;
                    width: 70%;
                }
            }
        }
    }

    .plantas {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        background-image: url(../img-general/plantas-de-tratamiento-y-envasado-de-agua.webp);           
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &::before {
            content: "";
            padding-top: 35%;
            @include media("<=phone") {
                padding-top: 80%;
            }
        }
        
        h2 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            font-size: 24px;
            color: white;
            text-align: center;
            line-height: 1.1;
            border: 1px solid  white;
            padding: 10px 0;
            border-radius: 100px;
            text-transform: uppercase;
            background-color: rgba(0, 0, 0, 0.4);
            transition: all 0.2s;
            &:hover {
                background-color: rgba(0, 0, 0, 0.4);
                transform: translate(-50%, -50%) scale(1.05);
                cursor: pointer;
            }
            @include media("<=desktop") {
                font-size: 18px;
                width: 70%;
            }
            @include media("<=phone") {
                font-size: 18px;
                width: 90%;
            }
        }
    }
}

