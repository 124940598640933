@import "./globals";
@import "include-media";

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

.MainSlider {
    @mixin noborder {
        &:active {
            border: none;
            outline: none;
        }
        &:focus {
            border: none;
            outline: none;
        }
    }
    box-sizing: border-box;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    border: none;
    outline: none;
    @include noborder;
    .sliderWrapper {
        position: relative;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        @include noborder;
        .mySlide {
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            width: 100vw !important;
            height: 100vh;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%; 
                height: 100%; 
                object-fit: cover;
                object-position: center;
                @include noborder;
            }
            video {
                margin: 0 auto;
                position: relative;
                width: 70%;
                height: 70%;
                object-fit: cover;
                top: 50%; 
                left: 50%; 
                transform: translate(-50%, -50%);
                @include noborder;
                @include media("<=phone") {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.thetitle {
    h1 {
        position: absolute;
        margin: 0 auto;
        color: dodgerblue;
        font-size: 68px;

        width: 40%;
        font-weight: 700;
        line-height: 0.9em;
        position: absolute;
        left: 50%;
        bottom: 1em;
        text-align: left;
        @include media("<=phone") {
            width: 100%;
            font-size: 36px;
            left: 0%;
            text-align: center;
            padding: 15px;
            margin: 0;
        }
        @include media(">phone", "<=tablet") {
            width: 100%;
            font-size: 42px;
            left: 0%;
            text-align: center;
            padding: 15px;
            margin: 0;
        }
        @include media(">tablet", "<=1024px") {
            width: 100%;
            font-size: 48px;
            left: 0%;
            text-align: center;
            padding: 15px;
            margin: 0;
        }
        @include media(">1024px", "<=1366px") {
            width: 42%;
            font-size: 54px;
            margin: 0;
        }
        @include media(">1366px", "<=1500px") {
            width: 42%;
            font-size: 54px;
            margin: 0;
        }
    }
}

.nextButton,
.previousButton {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 64px;
    height: 64px;
    text-indent: -9999px;
    transition: all 0.4s;
    cursor: pointer;
    border: none;
    outline: none;
    @include media("<=tablet") {
        display: none;
    }
}
.nextButton {
    right: 0;
}
.previousButton {
    transform-origin: 50% 50%;
    transform: translate(100%, 50%);
    background: url(../img-general/prevbutton.svg) no-repeat center center / 64px;
}
.previousButton:hover {
    background: url(../img-general/prevbuttonactive.svg) no-repeat center center / 64px;
}
.nextButton {
    transform-origin: 150% 50%;
    transform: translate(-100%, 50%);
    background: url(../img-general/nextbutton.svg) no-repeat center center / 64px;
}
.nextButton:hover {
    background: url(../img-general/nextbuttonactive.svg) no-repeat center center / 64px;
}

