
@import './globals';
@import 'include-media';

.Contactos {
    position: relative;
    width: 100%;

    .Contactanos {

        //  @include setSize();  ----------------------------------------------

        @mixin setSize($width, $height) {
            &:before {
                content: "";
                display: block;
                padding-top: $height;
            }
            display: flex;
            justify-content: center;
            align-items: stretch;
            width: $width;
            padding: 0.9rem 1.1rem;
            @include media("<=phone") {
                padding: 0.8rem 1rem;
            }
            input, textarea {
                width: 100%;
                resize: none;
                font-size: 18px;
                padding-left: 1em;
                outline: 0;
                border: 1px solid rgba(128, 128, 128, 0.5);
                border-radius: 0.3em;
                &:before {
                    content: "";
                    display: block;
                    padding-left: 0;
                    padding-top: 100%;
                }
                &::placeholder {
                    color: #006D9C;
                }
                &:focus {
                    outline: 0;
                    border: 1px solid rgba(128, 128, 128, 0.0) ;
                    box-shadow: 0 0 0 1px rgba(21, 156, 228, 0.9);
                }
            }
            input[type=submit] {
                background: #0071BA;
                width: auto;
                padding: 0 5rem;
                color: white;
                border: none;
                cursor: pointer;
                transition: all 0.4s;
                @include media("<=phone") {
                    padding: 0.5rem 4rem;
                }
                &:hover {
                    background: white;
                    color: #0071BA;
                    border: 1px solid #0071BA;
                }
            }
        }
        // ----------------------------------------------------------------------

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        background-color: #F0F0F0;
        margin: 2rem 0;
        form {
            width: 1400px;
            display: grid;
            // border: 1px dashed black;
            padding: 2rem 0;
            //--------------------------------------------------------------------
            grid-template-areas: 
                        "conta  whats"
                        "nombr  email"
                        "empre  telef"
                        "escri  escri"
                        "envia  envia";
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(5, auto);
            grid-auto-flow: row;
            @include media("<=tablet") {
                display: block;
            }
            
            & > div:nth-child(1) {
                grid-area: conta;
                @include setSize(100%, 12%);
                @include media("<=phone") {
                    @include setSize(100%, 15%);
                }
            }
            & > div:nth-child(2) {
                grid-area: whats;
                @include setSize(100%, 12%);
                @include media("<=phone") {
                    @include setSize(100%, 15%);
                }
                img {
                    width: 50px;
                    margin-right: 15px;
                }
            }
            & > div:nth-child(3) {
                grid-area: nombr;
                @include setSize(100%, 11%);
                @include media("<=phone") {
                    @include setSize(100%, 15%);
                }
            }
            & > div:nth-child(4) {
                grid-area: email;
                @include setSize(100%, 11%);
                @include media("<=phone") {
                    @include setSize(100%, 15%);
                }
            }
            & > div:nth-child(5) {
                grid-area: empre;
                @include setSize(100%, 11%);
                @include media("<=phone") {
                    @include setSize(100%, 15%);
                }
            }
            & > div:nth-child(6) {
                grid-area: telef;
                @include setSize(100%, 11%);
                @include media("<=phone") {
                    @include setSize(100%, 18%);
                }
            }
            & > div:nth-child(7) {
                grid-area: escri;
                @include setSize(100%, 18%);
                @include media("<=phone") {        // Not really important
                    @include setSize(100%, 35%);
                }
            }
            & > div:nth-child(8) {
                grid-area: envia;
                @include setSize(100%, 5%);
            }
            //--------------------------------------------------------------------
            & > div > div {
                width: 100%;
                padding-left: 0;
            }
            h2 {
                width: 100%;
                color: rgb(255, 0, 0);
                font-size: 36px;
            }
            p {
                width: 100%;
                color: $azulfont;
                font-size: 18px;
                font-weight: 700;
            }
            & > div:nth-child(2) > div {
                display: flex;
                align-items: center;
            }
        }
    }
}