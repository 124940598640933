
@import './globals';
@import 'include-media';

.Nosotros {
    position: relative;
    width: 100%;

    .Elegirnos {
        background-image: url(../img-general/background-porque-elegirnos.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 30px 0;
        & > div {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            max-width: $xlwidth;
            padding: 25px 50px;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                flex-basis: 0;
                h2 {
                    margin-top: 0.5em;
                    text-align: center;
                    color: white;
                    font-size: 36px;
                    text-transform: uppercase;
                    line-height: 1.2;
                    letter-spacing: 0.1em;
                }
                img.icon {
                    width: 60px;
                    margin: 0 auto;
                }
                img {
                    width: 80%;
                    @include media("<=phone") {
                        margin-top: 60px;
                        width: 90%;
                    }
                }
            }
            @include media("<=phone") {
                flex-direction: column;
                padding: 25px 0px;
            }
        }
    }
    
    .Especializados {
        width: 100%;
        background-color: #F0F0F0;
        & > div {
            max-width: $xlwidth;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;

            margin: 0.5em auto;
            padding: 30px 0;
    
            & > div {
                flex-grow: 1;
                flex-basis: 0;
                min-width: 250px;
                max-width: 350px;
                text-align: center;
                font-size: 2em;
                font-weight: 500;
                padding: 40px 32px;
                @include media("<=desktop") {
                    padding: 15px 32px;
                }
                h2 {
                    color: #FF0000;
                    font-size: 36px;
                    text-align: center;
                    padding: 0.2em 0;
                    @include media("<=desktop") {
                        font-size: 24px;
                    }
                }
                
                img {
                    width: 76px;
                    @include media("<=tablet") {
                        width: 60px;
                    }  
                }
                p {
                    font-size: 18px;
                    font-weight: 700;
                    color: $azulfont;
                    line-height: 1;
                    margin-bottom: 1em;
                }
            }
        }
    }
}

