
@import './globals';
@import 'include-media';

.Services {
    position: relative;
    width: 100%;

    .Ventajas {
        position: relative;
        background-color: #0088E0;
        padding: 0;
        .imgcontainer {
            background-image: url(../img-general/serviciofull.webp);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
            background-color: #0088E0;
            background-blend-mode: screen;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            &::after {
                content: "";
                padding-top: 31%;
                @include media("<=phone") {
                    padding-top: 80%;
                }
            }
            h1 {
                width: 100%;
                text-align: center;
                color: #2F2D7E;
                font-size: 36px;
                line-height: 1.2;   
                @include media(">desktop") {
                    font-size: 55px;
                }
            }
        }
    }

    .CardWrapper {
        margin: 0 auto;
        max-width: $xlwidth;
        padding: 0 50px;
        @include media("<=phone") {
            padding: 20px;
        }
        @include media(">phone", "<=tablet") {
            padding: 30px;
        }
        .Card {
            display: grid;
            grid-template-areas: "img cnt cnt";
            grid-template-columns: minmax(250px, 1fr) 2fr;
            background-color: #F0F0F0;
            margin: 15px 0;
            padding: 30px;
            @include media("<=phone") {
                display: block;
            }
            .theimage {
                grid-area: img;
                img {
                    width: 100%;                   
                }
             }
            .thedescription {
                grid-area: cnt;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 50px;
                padding-right: 50px;
                @include media("<=phone") {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 25px;
                }
                @include media(">phone", "<=tablet") {
                    padding-left: 25px;
                    padding-right: 25px;
                }
                font-size: 18px;
                line-height: 1;
                color: $azulfont;
                h2 {
                    font-size: 24px;
                    color: #FF0000;
                    padding-bottom: 15px;
                }
                ul {
                    list-style-position: inside;
                    list-style-type: square;
                    li {
                        padding-left: 0rem;
                    }
                }
            }
        }
    }
}

