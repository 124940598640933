
@import './globals';
@import 'include-media';

.Iso {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;

    .Iso9001 {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        background-color: #F0F0F0;
        margin: 2rem 0;
        .isocontainer {
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media("<=phone") {
                width: 80%;
            }
            &:before {
                content: "";
                display: block;
                padding-top: 52%;
            }
            img {
                width: 100%;
            }
        }
    }
}
