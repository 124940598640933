
@import 'globals';
@import 'include-media';

.Ldomestica {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .linea-domestica {
        position: relative;
        display: flex;
        width: 100%;
        background-image: url(../img-general/background-linea-domestica.webp);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        @include media("<=phone") {
            background-position: 100px 0;
        }
        &::after {
            content: "";
            padding-top: 25%;
        }
        @include media("<=phone") {
            &::after {
                content: "";
                padding-top: 60%;
            }
        }
        div {
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(2) {
                @include media("<=phone") {
                    flex-grow: 0;
                }
            }
        }
        .rotulo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 0px 25px 0;
            @include media("<=phone") {
                background-image: url(../img-general/background-linea-domestica.webp);
                background-position: right;
                background-size: cover;
            }
            img {
                width: 40%;
                padding-top: $xlHeaderHeight;
                @include media("<=phone") {
                    display: none;
                }
            }
            @include media("<=phone") {
                justify-content: flex-end;
                align-items: flex-end;
                padding: 0 25px 25px 0;
            }
            h1 {
                align-items: center;
                font-size: 72px;
                line-height: 1;
                text-align: end;
                color: #29A9E0;
                @include media("<=phone") {
                    font-size: 30px;
                    text-align: end;
                    max-width: 70%;
                    color: white;
                }
                @include media(">phone", "<=tablet") {
                    font-size: 36px;
                }
                @include media(">tablet", "<=desktop") {
                    font-size: 48px;
                }
                @include media(">desktop", "<=largedesktop") {
                    font-size: 60px;
                }
            }
        }
    }

    .cards-container {
        position: relative;
        width: 100%;
        .cards-wrapper {
            padding: 15px;
            position: relative;
            max-width: $xlwidth;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 5%;
            @include media("<=tablet") {
                padding: 15px 30px;
            }
            @include media('>tablet', '<1366px') {
                gap: 2%;
            }
            & > div {
                position: relative;
                flex-grow: 1;
                flex-basis: 0;
                min-width: 250px;
                max-width: 350px;
                text-align: center;
                margin: 20px;
                @include media("<=tablet") {
                    min-width: 250px;
                }
            }
        }
    }
}

