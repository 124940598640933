
@import './globals';
@import 'include-media';

.DummyHeader {
    position: relative;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: $xlHeaderHeight;
    @include media("<=phone") {
        height: $smHeaderHeight;
    }
    @include media(">phone", "<=tablet") {
        height: $mdHeaderHeight;
    }
}