
@import './globals';
@import 'include-media';

.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $xlHeaderHeight;
    display: flex;
    justify-content: center;
    z-index: 60;
    @include media("<=phone") {
        height: $smHeaderHeight;
    }
    @include media(">phone", "<=tablet") {
        height: $mdHeaderHeight;
    }
    transition: background-color 0.5s;

    header {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, minmax(auto, 1fr));
        grid-template-areas: "log nave nave nave nave";
        grid-auto-flow: row;
        justify-content: space-between;
        @include media("<=tablet") {
            grid-template-columns: repeat(3, minmax(auto, 1fr));
            grid-template-areas: "log nav burg";
        }
        height: $xlHeaderHeight;
        @include media("<=phone") {
            height: $smHeaderHeight;
            width: 95%;
        }
        @include media(">phone", "<=tablet") {
            height: $mdHeaderHeight;
            padding: 0 2%;
            width: 95%;
        }
        .logo {
            grid-area: log;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 100%;
            margin-right: 25%;
            img {
                width: 100%;
            }
            @include media("<=phone") {
                width: 100px;
                margin-right: 0%;
            }
            @include media(">phone", "<=tablet") {
                width: 150px;
                margin-right: 0%;
            }
        }
        nav {
            position: relative;
            grid-area: nave;
            height: 100%;
            width: 100%;
            padding-left: 5%;
            @include media("<=tablet") {
                padding-left: 25px;
            }
            & > ul {
                position: relative;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                & > li {
                    position: relative;
                    height: 100%;
                    font-size: 22px;
                    font-weight: 300;
                    list-style: none;
                    flex-grow: 1;
                    & > a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-grow: 1;
                        white-space: nowrap;
                        color: white;
                        text-decoration: none;
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        padding: 14px auto;
                        &:before{
                            content: '';
                            width: 0px;
                            position: absolute;
                            border-bottom: 8px solid #ffffff77;
                            bottom: 40px;
                            left: 50%;
                            transition: all 0.4s;
                        }
                        &:hover::before{
                            width: 70%;
                            left: 15%;
                        }
                    }
                    & > ul {
                        position: absolute;
                        top: 130px;
                        left: 25px;
                        list-style: none;
                        li {
                            background-color: $maincolor;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 0px 15px;
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.3s;
                            a {
                                color: white;
                                white-space: nowrap;
                                text-decoration: none;
                            }
                            &:hover{
                                background-color: darken($color: #0071BA, $amount: 5);
                            }
                        }
                    }
                    &:hover > ul > li {
                        overflow: visible;
                        max-height: 100px;
                        padding: 10px 15px;
                    }
                    @include media("<=phone") {
                        font-size: 18px;
                    }
                }
            }
            @include media("<=tablet") {
                display: none;
            }
        }
        .burger {
            @include media(">tablet") {
                display: none;
            }
            grid-area: burg;
            width: 40px;
            height: 40px;
            justify-self: flex-end;
            align-self: center;
            font-size: 40px;
            color: white;
            cursor: pointer;
        }
    }





    
    .close-burger {
        display: none;
        text-align: left;
        margin-left: 25px;
        padding-top: 10px;
        font-size: 0px;
        color: white;
        transition: all 0.4s;
        &:hover::before{
            height: 0;
            left: 0%;
        }
    }

    @include media("<=tablet") {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        overflow: hidden;
    
        header {
            position: relative;
            nav {
                overflow: hidden;
                ul {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    li {
                        position: relative;
                        width: 100%;
                        font-size: 22px;
                        font-weight: 300;
                        list-style: none;
                        flex-grow: 0;
                        a {
                            position: relative;
                            display: flex;
                            justify-content: flex-start;
                            width: 100%;
 
                            &:before{
                                content: '';
                                width: 0px;
                                position: absolute;
                                border-bottom: 50px solid #ffffff77;
                                bottom: 0px;
                                left: 0px;
                                transition: 0.4s all;
                            }
                            &:hover::before{
                                width: 100%;
                                left: 0%;
                            }
                        }
                    }
                }
            }
        }
    
        .navbar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        
        .close-burger {
            display: block;
            font-size: 40px;
            &:hover {
                color: white;
                &::before {
                    width: 0%;
                    left: 0%;
                }
            }
        }
        
        .nav-menu {
            background-color: $maincolor;
            width: 250px;
            height: 100vh;
            display: flex;
            justify-content: center;
            position: fixed;
            top: 0;
            left: -100%;
            transition: 850ms;
            padding-top: 15px;
        
            .nav-menu-items {
                width: 100%;
                .nav-togg {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                ul {
                    display: none;
                    height: auto;
                    background: black;
                    li {
                        height: auto;
                    }
                }
        
            }
            .nav-text {
                padding: 8px;
                list-style: none;
                height: 60px;
                a {
                    text-decoration: none;
                    color: #f5f5f5;
                    font-size: 22px;
                    padding: 0 16px;
                }
            }
        }
        
        .nav-menu.active {
            left: 0;
            transition: 350ms;
        }        
    }


}

.Header.active {
    background-color: $maincolor;
}

