
@import './globals';
@import 'include-media';

.Googlemaps {
            
    img {
        position: relative;
        min-height: 60vh;
        width: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;;
    }
}
