body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$maincolor: #0071BA;
$azulfont: #006D9C;

$xlwidth: 1530px;
$lgwidth: 1200px;
$mdwidth: 1024px;
$smwidth: 768px;
$xswidth: 324px;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}