
@import 'globals';
@import 'include-media';

.CardsContainer {
    position: relative;
    width: 100%;
    padding: 2rem;
    background-color: $backGray;
    @include media("<=phone") {
        padding: 2rem;
    }
    .cardsWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: $xlwidth;
        justify-content: center;
        margin: 0 auto;
        & > div {
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 380px;
            max-width: 410px;
            padding: 2rem;
            @include media("<=phone") {
                min-width: 325px;
            }
        }
    }
}

