
@import './globals';
@import 'include-media';

.Planta {
    position: relative;
    width: 100%;
    .theheader {
        background-image: url(../img-general/planta.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 2em;
        &::after {
            content: "";
            padding-top: 35%;
            @include media("<=phone") {
                padding-top: 100%;
            }
            @include media(">phone", "<=tablet") {
                padding-top: 50%;
            }
        }
        h2 {
            color: #0387DF;
            position: relative;
            font-size: 36px;
            text-align: center;
            line-height: 1;
            @include media("<=phone") {
                font-size: 18px;
            }
        }
    }
    .theimage {
        width: 60%;
        @include media("<=phone") {
            width: 85%;
        }
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
    .theprocessWrapper {
        max-width: 1200px;
        padding: 80px 80px;
        @include media("<=phone") {
            padding: 50px 15px;
        }
        margin: 0 auto;            
        background-color: #E7F4FC;

        .imgprocess {
            display: grid;
            width: 100%;
            max-height: 1200px;
            grid-template-areas:
            "imt imt imt imt"
            "dsc dsc dsc ims"
            "dsc dsc dsc ims"
            "imb imb imb imb";
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-auto-flow: row;
            gap: 15px;

            background-image: url(../img-general/proceso-planta-tratamiento.webp);
            background-position: center top;
            background-repeat: no-repeat;
            background-size: contain;
            .description {
                grid-area: dsc;
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                & > div {
                    position: relative;
                    border: 2px solid #0EB9ED;
                    border-radius: 15px;
                    width: 90%;
                    height: 100%;
                    display: flex;
                    max-width: 100%;
                    max-height: 100%;
                    justify-content: center;
                    align-items: center;
                    div {
                        h2 {
                            font-size: 24px;
                            color: $azulfont;
                            line-height: 2;
                        }
                        ul {
                            padding: 25px 0 25px 25px;
                            li {
                                line-height: 1.5;
                                font-size: 18px;
                                color: $azulfont;
                            }
                        }
                    }
                    &::before {
                        content: "";
                        display: block;
                        width: 0px;
                        height: 0px;
                        position: absolute;
                        top: 35px;
                        left: 0;
                        border-left: 15px solid #0EB9ED;
                        border-right: 15px solid transparent;
                        border-bottom: 15px solid transparent;
                        border-top: 15px solid transparent;
                    }
                }
            }
            &::after {
                content: "";
                padding-top: 110%;
            }
            @include media("<=1024px") {
                display: none;
            }
        }
        .thevector {
            display: none;
            @include media(">phone", "<1024px") {
                display: block;
            }
        }
        .thetext {
            display: none;
            @include media("<phone") {
                display: block;
            }
            & > div {
                border: 2px solid #0EB9ED;
                border-radius: 15px;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                justify-content: center;
                align-items: center;
                padding: 30px 15px;
 
                h2 {
                    font-size: 24px;
                    color: $azulfont;
                    line-height: 1;
                }
                ul {
                    padding: 10px 0 10px 25px;
                    li {
                        line-height: 1.2;
                        font-size: 18px;
                        color: $azulfont;
                        margin: 0.4em 0;
                    }
                }
            } 
        }
    }
}
