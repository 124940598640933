
@import './globals';
@import 'include-media';

.Footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 450px;
    @include media("<=phone") {
        height: 410px;
    }
    @include media(">phone", "<=tablet") {
        height: 425px;
    }
    @include media(">tablet", "<=desktop") {
        height: 440px;
    }
    @include media(">desktop", "<=largedesktop") {
        height: 455px;
    }
    @include media(">largedesktop") {
        height: 470px;
    }

    background-image: url(../img-general/background-footer-ozonorai.webp);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    .footer-wrapper {
        position: relative;
        max-width: $xlwidth;
        width: 100%;
        z-index: 10;
        .logo {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            display: flex;
            justify-content: center;
            z-index: -10;
            img {
                @include media("<=phone") {
                    width: 200px;
                }
                @include media(">phone", "<=tablet") {
                    width: 215px;
                }
                @include media(">tablet", "<=desktop") {
                    width: 230px;
                }
                @include media(">desktop", "<=largedesktop") {
                    width: 245px;
                }
                @include media(">largedesktop", "<=1360px") {
                    width: 260px;
                }
                @include media(">1360px") {
                    width: 275px;
                }
            }
        }

        .bottomText {
            position: relative;
            top: 50%;
            width: 100%;
            text-align: center;
            padding: 0 15%;
            @include media("<=phone") {
                top: 42%;
                padding: 0 5%;
            }
            @include media(">phone", "<=tablet") {
                top: 45%;
            }
            .txt {
                p {
                    width: 100%;
                    font-size: 18px;
                    color: $azulfont;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1.2;
                }
            }
            .web {
                a {
                    font-size: 45px;
                    color: #FF0000;
                    font-weight: 700;
                    text-decoration: none;
                    line-height: 1.5;
                    span {
                        font-size: 1.2em;
                    }
                    @include media("<=tablet") {
                        font-size: 22px;
                    }
                    @include media(">tablet", "<=desktop") {
                        font-size: 36px;
                    }
                }
            }
            .icons {
                a {
                    margin: 0  15px;
                    img {
                        width: 55px;
                        @include media("<=phone") {
                            width: 36px;
                        }
                    }
                }
            }
        }
    }

    .border {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(97% - 0rem);
        height: calc(95% - 0rem);
        transform: translate(1.5%, 0);

        border-top: 0;
        border-left: 2px solid white;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        z-index: 5;
        @include media("<=phone") {
            width: calc(97% - 0rem);
            height: calc(97% - 0rem);
            transform: translate(1.5%, 0);
        }
    }
}

