
@import 'globals';
@import 'include-media';

.GeneralHead {
    .dummy {

    }
    .titulos {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
            content: "";
            padding-top: 15%;
            @include media("<=phone") {
                padding-top: 70%;
            }
        }
        text-align: center;
        h1 {
            font-size: 56px;
            line-height: 1;
            @include media("<=phone") {
                font-size: 32px;
            }
            @include media(">phone", "<=768px") {
                font-size: 38px;
            }
            @include media(">768px", "<=1024px") {
                font-size: 44px;
            }
            @include media(">1024px", "<=1366px") {
                font-size: 50px;
            }

        }
        h2 {

            font-size: 42px;
            font-weight: 300;
            @include media("<=phone") {
                font-size: 18px;
            }
            @include media(">phone", "<=tablet") {
                font-size: 24px;
            }
            @include media(">tablet", "<=1024px") {
                font-size: 30px;
            }
            @include media(">1024px", "<=1366px") {
                font-size: 36px;
            }
        }

    }
}

