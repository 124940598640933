
@import './globals';
@import './include-media';

.pDetails {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #E4E4E4;
    .pWrapper {
        margin: 30px auto;
        width: 100%;
        max-width: $lgwidth;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .pImage {
            flex-basis: 0;
            flex-grow: 40;
            background-color: white;
            background-clip: padding-box;
            border: 1px solid rgba(128, 128, 128, 0.4);
            width: 100%;
            min-width: 500px;
            max-width: 500px;
            @include media("<=phone") {
                min-width: 250px;
                max-width: 500px;
                margin: 15px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
            max-height: 680px;
            overflow: hidden;
            img {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                object-position: center;
                object-fit: contain;
                transition: all 0.4s;
            }
            &::after {
                content: '';
                padding-top: 140%;
            }
            &:hover img {
                transform: scale(1.1);
            }
        }
        h2 {
            font-size: 18px;
            color: $azulfont;
        }
        p {
            margin: 15px 0;
            font-size: 18px;
            color: $azulfont;
            line-height: 1.2;
            span {
                font-weight: 700;
            }
        }
        li {
            margin: 10px 20px;
            padding-left: 5px;
            font-size: 18px;
            line-height: 1.2;
            color: $azulfont;
            span {
                font-weight: 700;
            }
        }
        .pDescription {
            flex-basis: 0;
            flex-grow: 70;
            min-width: 600px;
            max-width: 1000px;
            @include media("<=phone") {
                min-width: 250px;
                max-width: 500px;
                padding: 15px 15px 0 15px;
            }
            padding: 25px 25px 0 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .tData {
            margin-top: 30px;
            border: 1px solid rgba(173, 173, 173, 0.5);
            flex-basis: 0px;
            flex-grow: 40;
            min-width: 480px;
            max-width: 500px;
            @include media("<=phone") {
                min-width: 250px;
                max-width: 500px;
            }
            h2 {
                background-color: #CACACA;
                line-height: 3;
                padding-left: 15px;
            }
            @include media("<=phone") {
                ul {
                    padding-left: 30px;
                }
            }
            
            li {
                margin: 10px 40px;
                @include media("<=phone") {
                    margin: 10px 5px;
                    padding-left: 0px;
                }
            }
        }
        .buttons {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            @include media("<=phone") {
                flex-direction: column;
                gap: 1rem;
            }
            a {
                div {
                    width: 160px;
                    padding: 0.7em 1.2em;
                    text-align: center;
                    color: white;
                    font-weight: 700;
                    transition: all 0.4s;
                    cursor: pointer;
                }
                .consulta {
                    background-color: #0071BA;
                    border: 1px solid #0071BA;
                    &:hover {
                        background-color: white;
                        color: #0071BA;
                        border: 1px solid #0071BA;
                    }
                }
                .pedido {
                    background-color: #FF0000;
                    border: 1px solid #FF0000;
                    &:hover {
                        background-color: white;
                        color:  #FF0000;
                        border: 1px solid #FF0000;
                    }
                }
            }
        }
    }
}


