
@import './globals';
@import 'include-media';

.MisionVision {
    position: relative;
    width: 100%;

    .MisionVisionContainer {
        background-image: url(../img-general/background-mision-vision.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: white;
        background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        .mvgrid   {
            width: $xlwidth;
            display: flex;
            justify-content: center;
            flex-direction: row;
            padding: 0.5em 5%;
            & > div {
                flex-grow: 1;
                flex-basis: 0;
                flex-shrink: 1;
                padding: 30px;
                img {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: flex-end;
                    @include media("<=phone") {
                        display: none;
                    }
                }
                h2 {
                    line-height: 1.9;
                    font-size: 55px;
                    font-weight: 700;
                    color: white;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                }
                p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.4;
                    color: white;
                    margin-top: 1rem;
                }
            }
            & > div:nth-child(2) {
                padding: 0 5%;
            }
            @include media("<=phone") {
                flex-direction: column;
            }
        }
        &::before {
            content: "";
            display: block;
            padding-top: 35%;
        }
    }
}
